import React from 'react'
import { config, useSpring } from 'react-spring'
import { graphql } from 'gatsby'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import styled from 'styled-components'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'

type PageProps = {
  data: {
    file: {
      childImageSharp: {
        fluid: FluidObject
      }
    }
  }
}

const AuthorImage = styled.div`
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
`

const About: React.FunctionComponent<PageProps> = ({ data }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })
  return (
    <Layout color="#eee">
      <SEO
        title="About | Florian Maul"
        desc="Hi. I'm LekoArts! You can visit my website or my other Gatsby projects."
      />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>
          Hallo&nbsp;
          <span role="img" aria-label="winken">
            👋
          </span>
        </h1>
        <p>
          willkommen auf meiner kleinen Webseite. Ich habe hier eine große Auswahl meiner Fotos zusammengestellt und
          hoffe Du hast Freude Dir meine Fotos anzuschauen.
        </p>
        <p>
          Ich bin Florian, fotografiere schon seit vielen Jahren und habe 2013 angefangen Model-Shootings zu machen.
          Seit dem durfte ich schon mit vielen tollen Modellen zusammenarbeiten und freue mich immer wieder neue Ideen
          auszuprobieren und umzusetzen.
        </p>
        <p>
          Ich versuche in meiner Fotografie immer sinnliche Momente in Fotos einzufangen, die mal mehr mal weniger
          freizügig sind, aber dabei immer geschmackvoll bleiben.
        </p>
      </AnimatedBox>
      <AuthorImage>
        <GatsbyImage alt="Florian Maul" fluid={data.file.childImageSharp.fluid} />
      </AuthorImage>
    </Layout>
  )
}

export default About

export const query = graphql`
  query {
    file(sourceInstanceName: { eq: "images" }, name: { eq: "me" }) {
      childImageSharp {
        fluid(quality: 85, maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
